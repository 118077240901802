import React, { useEffect } from "react"
import { AppWrapper } from "../components/AppWrapper"
import { BackgroundSection } from "../components/Background/BackgroundSection"
import Footer from "../components/ui/Footer"
import { FormContact } from "../components/FormContact/FormContact"
import { Header } from "../components/Header"
import { SeFormerHypnose } from "../components/SeFormerHypnose/SeFormerHypnose"
import bg from "../components/SeFormerHypnose/bg.webp"
import { SVGWave3 } from "../components/Background/SVGWave3"
import { WidthContainer } from "../components/WidthContainer"
import { useMobile } from "../hooks/hooks.js"
import { FormPraticiens } from "../components/FormPraticiens/FormPraticiens"
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics"
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function IndexPage({ location }) {
    const { mobile } = useMobile();
    const { t } = useTranslation();
    // analytics - page viewed event
    useEffect(() => {
        if(!firebase) {
            return;
        }
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_practitioners_and_hypnosis_page_viewed)
    }, [firebase])

    return (
        <AppWrapper>
            <Header {...{ location: location.pathname }} />
            <div   {...{
                style: {
                    background: `url(${bg})`,
                    backgroundSize: "cover",
                    color: "white",
                    // margin: "36px 0"
                },
            }}>
                <WidthContainer center>
                    <div id="" className="" {...{
                        style: {
                            width: mobile ? "" : "66%"
                        },
                    }}>
                        <br />
                        <br />
                        <br />
                        <br />
                        {/* <br /> */}
                        <div id="" className="" {...{
                            style: {
                                fontSize: mobile ? "18px" : "36px",
                                fontWeight: "700",
                                marginBottom: "6px"
                            },
                        }}>
                            {t('hypnosis_practitioners.appointment')}
                        </div>
                        {/* <br /> */}
                        <div id="" className="" {...{
                            style: {
                                fontSize: mobile ? "13px" : "24px",
                                fontWeight: "400",
                                marginBottom: "6px"
                            },
                        }}>
                            {t('hypnosis_practitioners.description')}
                        </div>
                        {/* <br /> */}
                        {/* <br /> */}
                        {/* <br /> */}
                    </div>

                    {/* <div id="" className="" {...{ style: { height: "200px" }, }}> */}

                    {/* </div> */}

                </WidthContainer>
                <SVGWave3 style={{
                    gridArea: "a",
                    placeSelf: "end",
                }} {...{
                    fill: "#CFE0EE",
                }} />
            </div>

            <BackgroundSection hypnose>
                {/* <SeFormerHypnose /> */}
                <FormPraticiens title={t('hypnosis_practitioners.consultation_request_form')} />
            </BackgroundSection>
            <Footer />
        </AppWrapper>
    )
}
